// // ResponsiveIframe.js
// import React, { useRef, useEffect } from 'react';

// const ResponsiveIframe = ({ src }) => {
//   const iframeRef = useRef(null);

//   // Function to handle messages from the iframe
//   const handleMessage = (event) => {
//     // Optional: Verify the origin of the message for security
//     // if (event.origin !== 'https://www.example.com') return;
    
//     if (event.data.type === 'iframeHeight' && event.data.height) {
//       if (iframeRef.current) {
//         iframeRef.current.style.height = `${event.data.height}px`;
//       }
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('message', handleMessage);

//     return () => {
//       window.removeEventListener('message', handleMessage);
//     };
//   }, []);

//   return (
//     <div style={{ width: '100%', position: 'relative', overflow: 'hidden',padding:0 }}>
//       <iframe
//         ref={iframeRef}
//         src={src}
//         style={{ width: '100%', border: 'none', display: 'block',minHeight:500 }}
//         title="Responsive Iframe"
//       />
//     </div>
//   );
// };

// export default ResponsiveIframe;

// MemberSignup.js
import React, { useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';

const ResponsiveIframe = () => {
  const iframeRef = useRef(null);
  const cookies = new Cookies();

  useEffect(() => {
    // Simulate form submission on component mount
    const form = document.getElementById('member_signup_form');
    if (form) {     
      form.submit();
    }
  }, []);

  return (
    <div>
      <form
        id="member_signup_form"
        action="https://usjnet.sjp.ac.lk/sal_view/index.php"
        target="my-iframe"
        method="post"
        style={{ display: 'none' }}
      >
        <input type="text" name="usjmail" value={cookies.get('sjpEmail')} />
        <input type="text" name="strEmpNo" value={cookies.get('empNo')} />
      </form>

      <iframe
        ref={iframeRef}
        name="my-iframe"
        src="https://usjnet.sjp.ac.lk/sal_view/index.php"
        style={{ width: '100%', height: '800px', border: 'none' }}
        title="Salary View"
      />
    </div>
  );
};

export default ResponsiveIframe;

