import React, { useEffect, useState } from 'react'
import DataTableComponent from '../../common/data-table-component/data-table-component';
import { useSearch } from 'rsuite/esm/internals/Picker';

export default function UserEmailCreateComponent({emailCreateData}) {

const [rowData, setRowData] = useState([]);

const columns = [
  { key: 'id', header: 'ID', dataKey: 'id', width: 130 },
    { key: 'requestDate', header: 'Requested Date', dataKey: 'requestDate', width: 130 },
    { key: 'details', header: 'Details', dataKey: 'details', width: 230 },
    { key: 'preferredUserName', header: 'Preferred User Name', dataKey: 'preferredUserName', width: 230 },
    { key: 'extraDetails', header: 'Extra Details', dataKey: 'extraDetails', width: 230 },

    { key: 'sjpEmail', header: 'SJP Mail', dataKey: 'sjpEmail', width: 130 },
     { key: 'action', header: 'Action', dataKey: 'action', width: 130 },
  ];

  useEffect(()=>{
    console.log(emailCreateData[76]?.personal_details[0]?.employee_no);
    var res = emailCreateData.map((val,index)=>({
        id : index+1,
        requestDate: val?.request_date,
        details: val?.personal_details[0]?.employee_no+"\n"+val?.personal_details[0]?.employee_work_type+"\n"+val?.personal_details[0]?.department_name+"\n"+val?.personal_details[0]?.faculty_name+"\n"+"Terminate Date: "+val?.personal_details[0]?.salary_termination_date_1,
        preferredUserName:"Name 01 - "+val?.preferred_user_name?.preferred_user_name_1+"\n"+"Name 02 - "+val?.preferred_user_name?.preferred_user_name_2,
        extraDetails:val?.extra_details?.title_name+" "+val?.extra_details?.initials+" "+val?.extra_details?.last_name+"\n"+val?.extra_details?.name_denoted_by_initials+"\n"+val?.extra_details?.alternate_email_address+"\n"+val?.extra_details?.current_sjp_mail,
        sjpEmail:"",
    }));
    setRowData(res);

  },[emailCreateData])

  // const data = [{ id: 1, requestDate: '2024-05-10', empNo: '300032', name: 'Mr.. W.M.D. Priyankara', faculty: 'Faculty of Technology', action:"action" },{ id: 2, firstName: 'John', lastName: 'Doe' }];

   const renderRowExpanded = rowData => {
    return (
     <div>
        <div className='row'>
            <div className='col-sm-4'>
                <h5>Details</h5><br/>
                300032
                Lecturer
                Civil and Environmental Technology
                Terminate Date - 2025-02-14
                Faculty of Technology
            </div>
            <div className='col-sm-8'>
                

            </div>

        </div>
      </div>
    );
  };

  return (
     <div class="product__discount">
        <div class="section-title product__discount__title">
            <h2>Create Email Accounts</h2>
            <div style={{ padding:20 }}></div>
            <DataTableComponent columns={columns} data={rowData} renderRowExpanded={renderRowExpanded}/>
        </div>               
    </div>
  )
}
