import React, { Fragment, useEffect, useState } from 'react'
import NavbarComponent from '../components/navbar-component/navbar-component'
import ListComponent from '../components/common/list-component'
import LeftSideListComponent from '../components/home-page-component/left-side-list-component'
import { Breadcrumb, Footer } from 'rsuite'
import FooterComponent from '../components/common/footer-component/footer-component'
import SectionHeaderCompnent from '../components/home-page-component/section-header-component/section-header-component'
import SectionOneComponent from '../components/home-page-component/section-one/section-one-component'
import SectionTwoComponent from '../components/home-page-component/section-two/section-two-component'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import axios from '../api/axios'
import DynamicIframeComponent from '../components/dynamic-iframe-component/dynamic-iframe-component'
import IframeComponent from '../components/dynamic-iframe-component/common-iframe'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useServices from '../hooks/useServices'

export default function ApplyApplicationPage() {

  const privateAxisos = useAxiosPrivate();
  const [initialData, setInitialData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [services] = useServices();
  const navigate = useNavigate();


  return (
    <Fragment>
        <NavbarComponent />
        <div class="container">
      <div>
        <Breadcrumb separator={">"}>
            <Breadcrumb.Item as={Link} onClick={()=>navigate("/home")} href="#">
            Home
            </Breadcrumb.Item>
            <Breadcrumb.Item as={Link} >
            Aplication
            </Breadcrumb.Item>
        </Breadcrumb>
    </div>
    </div>
        
        <IframeComponent iframeSrc={services.iFarameURL}/>
        
        <FooterComponent />
    </Fragment>
   
  )
}
