import React from 'react';
import styled from 'styled-components';
import { Container, Header, Content, Nav, Navbar, Avatar, Icon, Panel, Row, Col } from 'rsuite';


const NavbarBrand = styled.a`
  color: white;
  font-size: 24px;
  margin: 0 20px;
`;

const StyledAvatar = styled(Avatar)`
  display: block;
  margin: 20px auto;
`;

const StyledPanel = styled(Panel)`
  text-align: left;
  margin: 10px;
  padding: 20px;
`;

const StyledH3 = styled.h3`
  text-align: center;
`;

const StyledH4 = styled.h4`
  margin-top: 20px;
`;

const StyledButton = styled.button`
  display: inline-block;
  margin: 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ProfilePage = () => {
  return (
    <Container>
      <Header>
        <Navbar appearance="inverse">
          <Navbar.Header>
            <NavbarBrand href="#">Kodecolor</NavbarBrand>
          </Navbar.Header>
          <Navbar.Body>
            <Nav pullRight>
              <Nav.Item >Find people</Nav.Item>
              <Nav.Item >Messages</Nav.Item>
              <Nav.Item>My Contacts</Nav.Item>
              <Nav.Item><Avatar circle src="    " alt="User" /></Nav.Item>
            </Nav>
          </Navbar.Body>
        </Navbar>
      </Header>
      <Content>
        <Row>
          <Col xs={24} md={8}>
            <StyledPanel bordered>
              <StyledAvatar circle size="lg" src="https://via.placeholder.com/100" alt="User" />
              <StyledH3>Jeremy Rose</StyledH3>
              <p>Product Designer</p>
              <p> New York, NY</p>
              <p> 8.6</p>
              <StyledButton>Send Message</StyledButton>
              <StyledButton>Contacts</StyledButton>
            </StyledPanel>
          </Col>
          <Col xs={24} md={16}>
            <StyledPanel bordered>
              <StyledH4>Work</StyledH4>
              <p>Spotify New York <br /> 170 William Street, New York, NY 10038</p>
              <p>Metropolitan Museum <br /> 525 E 68th Street, New York, NY 10065</p>
              <StyledH4>Skills</StyledH4>
              <p>Branding, UX/UI Design, Packaging, Print & Editorial</p>
              <StyledH4>Contact Information</StyledH4>
              <p>Phone: +1 123 456 7890</p>
              <p>Email: hello@jeremyrose.com</p>
              <p>Site: www.jeremyrose.com</p>
              <StyledH4>Basic Information</StyledH4>
              <p>Birthday: June 5, 1992</p>
              <p>Gender: Male</p>
            </StyledPanel>
          </Col>
        </Row>
      </Content>
    </Container>
  );
};

export default ProfilePage;
