import React, { Fragment } from 'react'
import AutoplaySlider from '../autoplay-slider'
import CardListComponent from '../card-list-component'
import LeftSideListComponent from '../left-side-list-component'

export default function SectionHeaderCompnent({initialData,isLoading}) {
  return (
    <Fragment>
        <section class="hero ">
        <div class="container">
            <div class="row">
                 <div className="col-lg-8 mt-4" >

                   <AutoplaySlider />

                   <CardListComponent isLoading={isLoading} initialData={initialData}/>
                        
                </div>

                <div className="col-lg-4 mt-4">
                    <div class="hero__categories">

                        <div class="section-title">
                        <h2>Applications</h2>
                    </div>
                        <ul>   
                            <LeftSideListComponent isLoading={isLoading} initialData={initialData}/>           
                        </ul>                        
                                        
  
                    </div>
                </div>
               
            </div>
        </div>
    </section>
    </Fragment>
  )
}
