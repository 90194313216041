import React from 'react'
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/home-page';
import CreateAccountPage from '../pages/create-account-page';
import LoginPage from '../pages/login-page';
import ChangeAccountPasswordPage from '../pages/change-account-password-page';
import AdminPage from '../pages/admin-page';
import ProfilePage from '../pages/profile-page';
import LayoutComponent from '../components/layout/Layout';
import UnauthorizedComponent from '../components/unauthorized-component/unauthorized-component';
import BadRequestComponent from '../components/bad-request/bad-request-component';
import NotFoundComponent from '../components/not-found-component/not-found-component';
import RequireAuth from '../components/auth-component/requireAuth';
import useServices from '../hooks/useServices';
import useAuth from '../hooks/useAuth';
import Cookies from 'universal-cookie';
import UserLoginSSO from '../components/auth/user-login';
import UserCallbackSSO from '../components/auth/user-call-back';
import UserConnectSSO from '../components/auth/user-connect';
import DetailsPage from '../pages/details-page';
import ApplyApplicationPage from '../pages/apply-application-page';

export default function AppRoute() {

const cookies = new Cookies(null, { path: '/' });
   const { auth, setAuth } = useAuth();
  //  const [ services] = useServices();
  //  const USERPRIVILAGES = services.USERPRIVILAGES;

  return (
    // <Routes>
    //     <Route path="/" element={<LoginPage />} />
    //     <Route path="/create-account" element={<CreateAccountPage />} />
    //     <Route path="/change-account-password" element={<ChangeAccountPasswordPage />} />
        // <Route path="/admin" element={<AdminPage />} />
        // <Route path="/home" element={<HomePage />} />
        // <Route path="/ProfilePage" element={<ProfilePage />} />
    // </Routes>

      <Routes>
        <Route path="/" element={<LayoutComponent />}>
       
        <Route exact path="/" element={<LoginPage />} />
         <Route exact path="/user-login" element={<UserLoginSSO />} />
         <Route exact path="/user_callback" element={<UserCallbackSSO />} />
         <Route path="/user_connect" component={<UserConnectSSO />} />
        <Route exact path="/create-account-test" element={<CreateAccountPage />} />
        <Route exact path="/create-account" element={<CreateAccountPage />} />
        <Route exact path="/change-account-password" element={<ChangeAccountPasswordPage />} />
        <Route path="unauthorized" element={<UnauthorizedComponent />} />
        <Route path="badrequest" element={<BadRequestComponent />} />
        
        <Route element={<RequireAuth allowedRoles={["logged"]} />}>
        <Route path="*" element={<NotFoundComponent />} />       
         </Route>
         <Route element={<RequireAuth allowedRoles={["logged"]} />}> 
            <Route path="/admin" element={<AdminPage />} /> 
         </Route> 
         <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/home" element={<HomePage />} />
         </Route>
         <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/apply-application" element={<ApplyApplicationPage />} />
         </Route>
          <Route path="/details" element={<DetailsPage />} />
         <Route element={<RequireAuth allowedRoles={["logged"]} />}>
            <Route path="/ProfilePage" element={<ProfilePage />} />      
         </Route>

         </Route>
      </Routes>
  )
}
