import React, { Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Avatar, Whisper, Popover } from 'rsuite';
import LogOutImage from "../../assets/logout.png";
import LogoImage from "../../assets/logo.png";

const DefaultPopover = React.forwardRef(({ content, ...props }, ref) => {
  return (
    <Popover ref={ref}  {...props}>
      <span>Profile</span>
    </Popover>
  );
});

const CustomComponent = ({ placement }) => (
  <Whisper
    trigger="click"
    placement={placement}
    controlId={`control-id-${placement}`}
    speaker={
        <DefaultPopover content={`I am positioned to the ${placement}`} />
    }
  >
    <Avatar color="white" bordered circle src="https://i.pravatar.cc/150?u=1" />
  </Whisper>
);

export default function NavbarComponent() {
  return (
    <Fragment>
      <Navbar key='sm' expand='sm' className="mb-3" style={{ backgroundColor: "#990000" }}>
        <Container>
          <Navbar.Brand style={{ color: 'white' }} href="/">
            <img src={LogoImage} alt="logo" style={{ marginRight: "20px" }} height={40} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                <img src={LogOutImage} alt="logo" width={20} height={20} style={{ filter: 'invert(1)' }} /> USJNet
              </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <Nav className="d-flex justify-content-end align-items-center flex-grow-1 pe-3">
                <div className="d-flex align-items-center">
                  <CustomComponent placement="bottomEnd" />
                  <Nav.Link className="ms-2" href="#logout">
                    <img src={LogOutImage} alt="logout" width={20} height={20} style={{ filter: 'invert(1)' }} />
                  </Nav.Link>
                </div>
              </Nav>
              {/* Uncomment and use if you need a search form
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="outline-success">Search</Button>
              </Form> */}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </Fragment>
  )
}
