import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";




const RequireAuth = ({ allowedRoles }) => {
    const { auth, setAuth } = useAuth();
    const location = useLocation();
    const cookies = new Cookies();
    const navigate = useNavigate();

    useEffect(() => {
   
    axios.get("https://usjnet.sjp.ac.lk/sso/api/availability")
      .then(async function (response) {
        if(response.status === 200){
          if(response.data.client_id === ""){
            window.location.href = `https://usjnet.sjp.ac.lk/login/user_login_sso`;
          }
        }else{
            window.location.href = `https://usjnet.sjp.ac.lk/login/user_login_sso`;
        }
      })
      .catch(function (error) { 
        window.location.href = `https://usjnet.sjp.ac.lk/login/user_login_sso`;
      });
  }, []);


    //   useEffect(()=>{

    //     try {
    //         // const refreshToken = auth?.refreshToken;
    //         // console.log(refreshToken);
    //         // setAuth({});

    //         // const tokenData = JSON.parse(atob(refreshToken.split('.')[1]));
    //         // const expirationTime = tokenData.exp * 1000; 
    //         // const currentTime = Date.now();
    //         // console.log(auth?.refreshToken,expirationTime,currentTime)
    //         // const isTokenHasTemp =  currentTime < expirationTime;
            
    //         // if(!isTokenHasTemp){
    //         //     setAuth({});
    //         //     cookies.remove('privilages', { path: '/' });
    //         //     cookies.remove('refreshToken', { path: '/' });
    //         //     cookies.remove('userState', { path: '/' });
    //         //     window.location.href = '/';
                
    //         // }

    //       } catch (error) {
    //         // setAuth({});
    //         // cookies.remove('privilages', { path: '/' });
    //         // cookies.remove('refreshToken', { path: '/' });
    //         // cookies.remove('userState', { path: '/' });
    //         // window.location.href = '/';  
            
    //       }
    // },[])
    return (
      
      typeof cookies.get('accessToken') !== 'undefined' &&  cookies.get('privilages').find(privilage => allowedRoles?.includes(privilage))
            ? <Outlet />
            :typeof cookies.get('userState') !== 'undefined' && cookies.get('userState') !== "logged"
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/" state={{ from: location }} replace />
       
    );
}


export default RequireAuth;