import React from 'react'
import DataTableComponent from '../../common/data-table-component/data-table-component'

export default function USJNetMembersComponent() {

    const columns = [
    { key: 'firstName', header: 'First Name', dataKey: 'firstName', width: 130 },
    { key: 'lastName', header: 'Last Name', dataKey: 'lastName', width: 130 },
    // Add more columns as needed
  ];

  const data = [{ id: 1, firstName: 'John', lastName: 'Doe' },{ id: 2, firstName: 'John', lastName: 'Doe' }];

   const renderRowExpanded = rowData => {
    return (
      <div>
        <div
          style={{
            width: 60,
            height: 60,
            float: 'left',
            marginRight: 10,
            background: '#eee'
          }}
        >
          <img src={rowData.avatar} style={{ width: 60 }} />
        </div>
        <p>Email: {rowData.email}</p>
        <p>Phone: {rowData.phone}</p>
      </div>
    );
  };

  return (
     <div class="product__discount">
                        <div class="section-title product__discount__title">
                            <h2>USJnet Members</h2>
                            <div style={{ padding:20 }}></div>
                            <DataTableComponent columns={columns} data={data} renderRowExpanded={renderRowExpanded}/>
                        </div>
                       
                    </div>
  )
}
