
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const IframeComponent = ({ iframeSrc }) => {
  const handleLoad = (event) => {
    // Scroll the parent window to the top
    window.scrollTo(0, 0);
  };
   const navigate = useNavigate();

  useEffect(()=>{
    if(iframeSrc == ""){
        navigate("/home");
    }
  },[])

  // Encode the user registration number for the URL
//   const encodedUserRegNo = encodeURIComponent(userRegNo);
//   const iframeSrc = src +"/"+ encodedUserRegNo;

  return (
    <iframe
      id="iframecss"
      src={iframeSrc}
      onLoad={handleLoad}
      style={{
        width: '100%',
        height:'500px',
        border: 'none',
        overflow: 'auto',
      }}
      title="Vehicle Pass"
    />
  );
};

export default IframeComponent;
