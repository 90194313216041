import React, { Fragment, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Button } from 'rsuite'

export default function MobileVerificationComponent({userDetails,handleChange,handleOnSubmitSendVerification,handleOnSubmitVerifyVerification}) {

const [isSendVerification, setIsSendVerification] = useState(false);
const [enterdMobile,setEnterdMobile] = useState("");
  const [validated, setValidated] = useState(false);

const [errors, setErrors] = useState({
    mobileNo: '',
   
  });

const changeIsSendVerification = (event) => {
    setErrors({
      ...errors,
      ["mobileNo"]: '',
    });
    if(enterdMobile === userDetails?.mobileNo){
        setIsSendVerification(true);
         handleOnSubmitSendVerification();
    }
    event.preventDefault();
        if (validateForm()) {
           
        setValidated(true);
        } else {
        setValidated(false);
        }
 
}

const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (userDetails.mobileNo !== enterdMobile) {
      valid = false;
      newErrors.mobileNo = 'Invalid Mobile No';
    }
    setErrors(newErrors);
    return valid;

  };
    const onSubmitAction = () => {
        handleOnSubmitVerifyVerification();
    }

  return (
    <Fragment>
                    
                                <Form noValidate validated={validated} onSubmit={changeIsSendVerification}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={3}>
                                        Mobile No
                                        </Form.Label>
                                        <Col sm={9}>
                                        <Form.Control isInvalid={!!errors.mobileNo} type="text" name="mobileNo" onChange={(val)=>setEnterdMobile(val.target.value)} disabled={!isSendVerification ? false : true} placeholder="Mobile No" />
                                        <p style={{ color:"red",fontSize:12 }}>{errors.mobileNo}</p>
                                        </Col>
                                    </Form.Group>

                                        <Form.Group as={Row} className="mb-3">
                                            <Col>
                                                {/* Empty space to push the button to the end */}
                                            </Col>
                                            <Col xs="auto">
                                                <Button disabled={!isSendVerification ? false : true}  style={{ width: '180px', backgroundColor: !isSendVerification ? '#990000' : 'gray' , color: 'white' }} type="submit">Send OTP</Button>
                                            </Col>
                                        </Form.Group>

                                    </Form>
                        
                    

                        {isSendVerification && (
                            <Form>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={3}>
                                                OTP
                                                </Form.Label>
                                                <Col sm={9}>
                                                <Form.Control type="email" onChange={(val)=>handleChange('otp',val.target.value) } name='otp' placeholder="Verification Code" />
                                                </Col>
                                            </Form.Group>

                                                <Form.Group as={Row} className="mb-3">
                                                    <Col>
                                                        {/* Empty space to push the button to the end */}
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button onClick={onSubmitAction} style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="button">Verify</Button>
                                                    </Col>
                                                </Form.Group>

                                            </Form>
                        )}
                        
    </Fragment>
  )
}
