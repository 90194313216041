import { Navigate, useLocation } from 'react-router-dom';
import axios from '../api/axios';
import useAuth from './useAuth';
import useAxiosRefresh from './useAxiosRefresh';

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();
    const axiosRefresh = useAxiosRefresh();
    const location = useLocation();

    const refresh = async () => {
        const postData = {
            accessToken : auth?.accessToken,
            refreshtoken : auth?.refreshToken,

        }   
        console.log(auth?.refreshToken)
        const response = await axiosRefresh.post('/Students/refresh',postData);
        console.log("Create new jwt web token");

        return response?.data?.newAccessToken;
    }
    return refresh;
};

export default useRefreshToken;
