import axios from 'axios';
import { axiosPrivate } from '../api/axios';

const BaseUrl = "https://jsonplaceholder.typicode.com";
const controller = new AbortController();

export const GetUser = () => {
   return axios.get(BaseUrl + '/todos/1');
}

export const GetSingleAssignmentDetails = async (assignmentUuid) => {
   return await axiosPrivate.get('/assignment/'+assignmentUuid+'/student', {
      signal: controller.signal
    });
}
