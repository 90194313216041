import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserConnectSSO = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("start");
    const access_token = localStorage.getItem("authToken");

    if (!access_token) {
      console.error('No access token found');
      return;
    }
console.log("fetching start");
    fetch('https://usjnet.sjp.ac.lk/sso/api/user', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setUserData(data);
        // Perform any additional checks or actions
        // checkUserEmail(data.email);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [navigate]);

  return (
    <div>
      <h1>User Information</h1>
      {userData ? (
        <div>
          
          <p>Email: {userData.email}</p>
          {/* Display other user information as needed */}
        </div>
      ) : (
        <div>Loading user information...</div>
      )}
    </div>
  );
};

export default UserConnectSSO;
