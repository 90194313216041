import axios from 'axios';
import { toast } from 'react-toastify';
import { Configurations } from '../config';
import Cookies from 'universal-cookie';
//const BASE_URL = 'http://192.168.1.16:7286/api';

//For Testing
//const BASE_URL = 'https://econworld.xenosyslab.com/api';

//For MyEcon
//const BASE_URL = 'https://appmyecon.xenosyslab.com/api';

//For WisePath
//const BASE_URL = 'https://appwisepath.xenosyslab.com/api';

//For GradeLK

const BASE_URL = Configurations.baseUrl + '/api';

export const BaseUrl =  BASE_URL;
const cookies = new Cookies();

export default axios.create({
    baseURL: BASE_URL,
    headers: {'X-Version':'1.0','Authorization': `Bearer ${cookies.get('accessToken')}`},
    //timeout: 5000,
});

export const axiosFormData = axios.create({
  baseURL: BASE_URL,
  headers: { 'content-type': 'multipart/form-data','X-Version':'1.0'},
  withCredentials: true,
  //timeout: 5000,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json','X-Version':'1.0'},
    withCredentials: true,
    //timeout: 5000,
  
});

export const axiosRefresh = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json','X-Version':'1.0'},
    withCredentials: true,
    //timeout: 5000,
  
});

axiosPrivate.interceptors.request.use(
    (config) => config,
    (error) => {
      if (axios.isCancel(error)) {
        toast.error('Your Request is cancled', {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
      return Promise.reject(error);
    }
  );

  axiosRefresh.interceptors.request.use(
    (config) => config,
    (error) => {
      if (axios.isCancel(error)) {
        toast.error('Your Request is cancled', {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
      return Promise.reject(error);
    }
  );
