import React, { Fragment, useEffect, useState } from 'react'
import { Button, Message, RadioTile, RadioTileGroup} from 'rsuite'
import { Col, Form, Row } from 'react-bootstrap'
import UserDetailsPanel from './user-details-panel';
import MobileVerificationComponent from './mobile-verification-component';
import EmailVerificationComponent from './email-verification-component';

export default function VerificationForm({handleOnSubmitRequestEmail=()=>{},handleOnSubmitApplyNewEmail=()=>{},handleChange,userDetails,handleOnSubmitSendVerification,handleOnSubmitVerifyVerification,showVerificationMethod=false, showEmpRequestEmail=false ,showInvalidEmailAddress=false ,showStudentHaventEmailAddress=false,showPendingEmailRequest=false,showMailRequestSuccessMessage=false,showAlreadyMemberMessage=false,showUserNotRegistedUser=false,validationPath = ""}) {

 const [verificationType, setVerificationType] = useState("");

 useEffect(()=>{
    
 },[showMailRequestSuccessMessage])
const changeVerificationType = (val) => {
    setVerificationType(val);
    handleChange('verificationType',val);
}

const [userDetailsApplyEmail,setUserDetailsApplyEmail] = useState({
    preferredUserName1: '',
    preferredUserName2: '',
    alternateEmailAddress: '',
});

const [userDetailsRequestEmail,setUserDetailsRequestEmail] = useState({
    emailAddress: '',
})

     const [validated, setValidated] = useState(false);
       const [errors, setErrors] = useState({
    preferredUserName1: '',
    preferredUserName2: '',
    alternateEmailAddress:'',
  });

  const [errorsReEmail, setErrorsReEmail] = useState({
    emailAddress: '',
  });


    const onSubmitActionApplyEmail = (event) => {
        event.preventDefault();
        if (validateForm()) {
            handleOnSubmitApplyNewEmail(userDetailsApplyEmail);
        setValidated(true);
        } else {
        setValidated(false);
        }
        
    }

    const onSubmitActionRequestEmail = (event) => {
        event.preventDefault();
        if (validateFormRequestEmail()) {
            handleOnSubmitRequestEmail(userDetailsRequestEmail);
        setValidated(true);
        } else {
        setValidated(false);
        }
        
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!userDetailsApplyEmail.preferredUserName1) {
        valid = false;
        newErrors.preferredUserName1 = 'Preferred User Name 1 is required';
        }

        if (!userDetailsApplyEmail.preferredUserName2) {
        valid = false;
        newErrors.preferredUserName2 = 'Preferred User Name 2 is required';
        }

        if (!userDetailsApplyEmail.alternateEmailAddress) {
        valid = false;
        newErrors.alternateEmailAddress = 'Alternate Email Address is required';
        }

        setErrors(newErrors);
        return valid;

    };

    const validateFormRequestEmail = () => {
        let valid = true;
        const newErrors = {};

        if (!userDetailsRequestEmail.emailAddress) {
        valid = false;
        newErrors.emailAddress = 'Email Address is required';
        }

        setErrorsReEmail(newErrors);
        return valid;

    };

    const onValueChangeApplyEmail = (event) => {
    const { name, value } = event.target;
    handleChangeApplyEmail(name,value);

    // Clear error when user starts typing
    setErrors({
      ...errors,
      [name]: '',
    });
  };
const handleChangeApplyEmail = (field, value) => {
    setUserDetailsApplyEmail(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const onValueChangeRequestEmail = (event) => {
    const { name, value } = event.target;

    handleChangeRequestEmail(name,value);

    // Clear error when user starts typing
    setErrorsReEmail({
      ...errors,
      [name]: '',
    });
  };

const handleChangeRequestEmail = (field, value) => {
    setUserDetailsRequestEmail(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  return (
    <Fragment>
        {showVerificationMethod && validationPath === "createNewAccount" && (
            <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                            <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                                <RadioTileGroup defaultValue="blank"  aria-label="Create new project">
                                    <RadioTile  label="Send Verification via Email"  value="email" onChange={(val)=>changeVerificationType(val)}>
                                        Send Verification via Email<br></br>
                                    </RadioTile>
                                    <RadioTile
                                        label="Send Verification via Mobile No"
                                        value="mobileNo"
                                        onChange={(val)=>changeVerificationType(val)}
                                    >
                                        Send Verification via Mobile No<br></br>
                                    </RadioTile>
                                    
                                    </RadioTileGroup>
                                    <div style={{ padding:'10px' }}></div>

                                    {verificationType === 'mobileNo' && (<MobileVerificationComponent userDetails={userDetails} handleOnSubmitSendVerification={handleOnSubmitSendVerification} handleOnSubmitVerifyVerification={handleOnSubmitVerifyVerification} handleChange={handleChange}/>)}
                                    {verificationType === 'email' && (<EmailVerificationComponent userDetails={userDetails} handleOnSubmitSendVerification={handleOnSubmitSendVerification} handleOnSubmitVerifyVerification={handleOnSubmitVerifyVerification} handleChange={handleChange}/>)}
                                
                            </Col>
                        </Row>
                    </Row>
        )}

        {showVerificationMethod && validationPath === "changeAccountPassword" && !showUserNotRegistedUser && (
            <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                            <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                                <RadioTileGroup defaultValue="blank"  aria-label="Create new project">
                                    <RadioTile  label="Send Verification via Email"  value="email" onChange={(val)=>changeVerificationType(val)}>
                                        Send Verification via Email<br></br>
                                    </RadioTile>
                                    <RadioTile
                                        label="Send Verification via Mobile No"
                                        value="mobileNo"
                                        onChange={(val)=>changeVerificationType(val)}
                                    >
                                        Send Verification via Mobile No<br></br>
                                    </RadioTile>
                                    
                                    </RadioTileGroup>
                                    <div style={{ padding:'10px' }}></div>

                                    {verificationType === 'mobileNo' && (<MobileVerificationComponent userDetails={userDetails} handleOnSubmitSendVerification={handleOnSubmitSendVerification} handleOnSubmitVerifyVerification={handleOnSubmitVerifyVerification} handleChange={handleChange}/>)}
                                    {verificationType === 'email' && (<EmailVerificationComponent userDetails={userDetails} handleOnSubmitSendVerification={handleOnSubmitSendVerification} handleOnSubmitVerifyVerification={handleOnSubmitVerifyVerification} handleChange={handleChange}/>)}
                                
                            </Col>
                        </Row>
                    </Row>
        )}

        {!showMailRequestSuccessMessage && showEmpRequestEmail && (
            <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                           <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                            <h6 style={{ marginBottom: 20 }}>You don't have sjp mail. Request sjp mail</h6>
                        <Form noValidate validated={false} onSubmit={onSubmitActionApplyEmail}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>
                            Preferred User Name 1
                            </Form.Label>
                            <Col sm={9}>
                            <Form.Control isInvalid={!!errors.preferredUserName1} type="text" name='preferredUserName1' placeholder="Preferred User Name 1" onChange={onValueChangeApplyEmail} />
                            <p style={{ color:"red",fontSize:12 }}>{errors.preferredUserName1}</p>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                            Preferred User Name 2
                            </Form.Label>
                            <Col sm={9}>
                            <Form.Control isInvalid={!!errors.preferredUserName2} type="text" name='preferredUserName2' placeholder="Preferred User Name 2" onChange={onValueChangeApplyEmail}/>
                            <p style={{ color:"red",fontSize:12 }}>{errors.preferredUserName2}</p>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                            <Form.Label column sm={3}>
                            Alternate Email Address
                            </Form.Label>
                            <Col sm={9}>
                            <Form.Control isInvalid={!!errors.alternateEmailAddress} type="email" name='alternateEmailAddress' placeholder="Alternate Email Address" onChange={onValueChangeApplyEmail}/>
                            <p style={{ color:"red",fontSize:12 }}>{errors.alternateEmailAddress}</p>
                            </Col>
                        </Form.Group>
                        
                       

                        <Form.Group as={Row} className="mb-3">
                            <Col>
                                {/* Empty space to push the button to the end */}
                            </Col>
                            <Col xs="auto">
                                <Button  style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="submit">Submit</Button>
                            </Col>
                            </Form.Group>
                        </Form>

                          
                            <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}>
                                OR
                            </Row>
                       <h6 style={{ marginBottom: 20 }}>If You have sjp mail. Request your sjp mail</h6>

                            <Form noValidate validated={false} onSubmit={onSubmitActionRequestEmail}>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                    SJP Email Address
                                    </Form.Label>
                                    <Col sm={9}>
                                    <Form.Control isInvalid={!!errorsReEmail.emailAddress} type="email" name='emailAddress' placeholder="SJP Email Address" onChange={onValueChangeRequestEmail} />
                                    <p style={{ color:"red",fontSize:12 }}>{errorsReEmail.emailAddress}</p>
                                    </Col>
                                </Form.Group>

                               

                                <Form.Group as={Row} className="mb-3">
                                    <Col>
                                        {/* Empty space to push the button to the end */}
                                    </Col>
                                    <Col xs="auto">
                                        <Button  style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="submit">Submit</Button>
                                    </Col>
                                    </Form.Group>
                                </Form>
                                  
                            </Col>
                    </Row>

                    

                    
        </Row>
        )}

        {showMailRequestSuccessMessage && showEmpRequestEmail && (
            <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                           <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                            <Message type="success" centered showIcon header="Your request successfully submitted.!">
                                    <p style={{ marginBottom: 20 }}>Your request successfully submitted</p>    
                                    <p>
                                        {/* <Button onClick={goBackLogin} style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="button">Go to Login-Page</Button> */}
                                    </p>
                                </Message>
                            </Col>
                    </Row>       
            </Row>
        )}

        {showInvalidEmailAddress && (
            <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                           <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                            <Message type="warning" centered showIcon header="Your email address is invalid. please contact the admin.!">
                                    <p style={{ marginBottom: 20 }}>Your email address is invalid. please contact the admin</p>    
                                    <p>
                                        {/* <Button onClick={goBackLogin} style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="button">Go to Login-Page</Button> */}
                                    </p>
                                </Message>
                            </Col>
                    </Row>       
            </Row>
        )}


        {showStudentHaventEmailAddress && (
            <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                           <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                            <Message type="warning" centered showIcon header="Some information is missing. please contact the admin.!">
                                    <p style={{ marginBottom: 20 }}>Some information is missing. please contact the admin</p>    
                                    <p>
                                        {/* <Button onClick={goBackLogin} style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="button">Go to Login-Page</Button> */}
                                    </p>
                                </Message>
   
                            </Col>
                    </Row>       
            </Row>
        )}

        {showPendingEmailRequest && (
             <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                           <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                            
                            <Message type="warning" centered showIcon header="Your email request is prograssing.!">
                                    <p style={{ marginBottom: 20 }}>Your email request is prograssing...</p>    
                                    <p>
                                        {/* <Button onClick={goBackLogin} style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="button">Go to Login-Page</Button> */}
                                    </p>
                                </Message>  
                            </Col>
                    </Row>       
            </Row>
        )}

        {showAlreadyMemberMessage && (
             <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                           <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                            
                            <Message type="warning" centered showIcon header="Your are already a member">
                                    <p style={{ marginBottom: 20 }}>Your are already a member</p>    
                                    <p>
                                        {/* <Button onClick={goBackLogin} style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="button">Go to Login-Page</Button> */}
                                    </p>
                                </Message>  
                            </Col>
                    </Row>       
            </Row>
        )}

        {showVerificationMethod && validationPath === "changeAccountPassword" && showUserNotRegistedUser && (
            <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}> 
                    
                    <Row className='col-sm-12'>
                        <Col sm={6}>
                           <UserDetailsPanel userDetails={userDetails}/>
                            </Col>
                            <Col sm={6}>
                            
                            <Message type="warning" centered showIcon header="Your are not already a member">
                                    <p style={{ marginBottom: 20 }}>please go and register</p>    
                                    <p>
                                        {/* <Button onClick={goBackLogin} style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} type="button">Go to Login-Page</Button> */}
                                    </p>
                                </Message>  
                            </Col>
                    </Row>       
            </Row>
        )}
  
       
    </Fragment>
  )
}
