import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";
import { toast } from "react-toastify";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    useEffect(() => {

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {

                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    // prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    prevRequest.headers['Authorization'] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5ODVjNGY1Ni1mZmIyLTQ3ZjgtYjVmYi03MTM0MDY4MDdkMDgiLCJqdGkiOiIzYTY3Yjk1MzAyMDUyZjgxZjY2NDRkZDUzMWZmMTQyZjRjNjE5ZGNmNmM4NGJlY2FkMGNlYzVjYzU2NDYwZjJlYmM2NzE4NDI2YWY3NjRiZSIsImlhdCI6MTcyNDAwMjQ4NC43Njk1MzIsIm5iZiI6MTcyNDAwMjQ4NC43Njk1MzksImV4cCI6MTcyNDA4ODg4NC41ODg0OTMsInN1YiI6IjY1NDQiLCJzY29wZXMiOlsidmlldy11c2VyIl19.GWDrvcqWvivu402qNzOXDEKkoiejhZRERWy_0K791Hb4SMBN6urXhrMc86859a-5kaP5t_V3ioKStisVC-QP7AsxGqLlZ3bq8rwohlK1tmzluTdl27wxTnoRwQxQjgkfNhX1dDkYlC-qCeZZHhDLgcaHpmsqzNNBDXAr9y1M3pc_QmQkpR4klmCFUSB7SiFefST8lLxTGBK2ijjIZAmvtX9zlmcVJPLMLOvJsXhc-fWfgqXaO8cF_5FZJjQpwPPiwVdswSjOiM0O82SAu7Yu_LveH_E-CfIXTZO4RVcznIkL6mbwr8EAU-8EdoaUj_s4_dUr_fbZTuQxajWnSK4nywBuvCytr3i9uIz29IUUY5fwGwmwdranHvkud3wi18bFPocLgbd2EbQQGigGEbIyhUeDiJ9ROSmnzKepX4w5ZlTw-oTZHWrC-U0urBP-fYvfGSrQ4z2MVuMDIMWMMis_a2oVaGjZu8t2Cv_CiIcCVXIrWvbqmLmTaEZSEFCE9XrUfCrkH6nfH6VzkU_3VCWPRhYNip2jcbWAgkE_Gpc5_JrmZeyaZJYlwRxsUCTxm51k_78DgjF9h69fWc0PTXf5JzBGLaixM2Xmeo4SvIrG-qmuiEq_roeOCEPJRT0XePVnqAG3s7cFJi59UFq5JhPhPdIm4nqjL5CvBMUyfS2nuh8`;
                    return axiosPrivate(prevRequest);
                }
                

                if (error.code === 'ERR_NETWORK') {
                    //console.log('connection problems..');
                    toast.error('connection problems', {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                  } else if (error.code === 'ERR_CANCELED') {
                    console.log('connection canceled..')
                    toast.error('connection canceled', {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                  }
                
                if (error?.response?.status === 400) {
                    toast.error(error?.response?.data, {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                   // window.location.href = '/badrequest';
                    //console.error('Bad Request:', error.response.data);
                }
                if (error?.response?.status === 404) {
                    toast.error('Not Found', {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                   // window.location.href = '/badrequest';
                    //console.error('Bad Request:', error.response.data);
                }
                if (error?.response?.status === 500) {
                    toast.error('Internal Server Error', {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                   // window.location.href = '/badrequest';
                    //console.error('Bad Request:', error.response.data);
                }
                if (error?.response?.status === 403) {
                    toast.error('Sorry ! You are Not Eligible to Access', {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                }
                if (error?.response?.status === 503) {
                    toast.error('Sorry ! Server Unavailable Handle this Request', {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                }
                return Promise.reject(error);
            }
        );

        return () => {
            //axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;