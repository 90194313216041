import React, { Fragment } from 'react'
import { Carousel } from 'rsuite';

export default function AutoplaySlider() {
  return (
    <Fragment>

        <Carousel autoplay className="custom-slider" style={{ height:"auto",width:"100%" }}>
    <img src="https://usjnet.sjp.ac.lk/assets/images/Ban-02.jpg"  />
     <img src="https://usjnet.sjp.ac.lk/assets/images/Ban-01.jpg"  />
     <img src="https://usjnet.sjp.ac.lk/assets/images/Ban-02.jpg"  />
     <img src="https://usjnet.sjp.ac.lk/assets/images/Ban-01.jpg"  />
      {/* <img src="https://usjnet.sjp.ac.lk/assets/images/Ban-02.jpg"  />
       <img src="https://usjnet.sjp.ac.lk/assets/images/Ban-02.jpg"  /> */}
    {/* <img src="https://via.placeholder.com/600x250/8f8e94/FFFFFF?text=2"  />
    <img src="https://via.placeholder.com/600x250/8f8e94/FFFFFF?text=3"  />
    <img src="https://via.placeholder.com/600x250/8f8e94/FFFFFF?text=4"  />
    <img src="https://via.placeholder.com/600x250/8f8e94/FFFFFF?text=5"  /> */}
  </Carousel>

    </Fragment>
  )
}
