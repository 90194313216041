import axios from '../api/axios';
import useAuth from './useAuth';

const useLogout = () => {
    const { auth, setAuth } = useAuth();

    const logout = async () => {

        //const response = await axios.post('/logout');
        return true;
    }
    return logout;
};

export default useLogout;
