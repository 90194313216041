import React, { useContext } from 'react'
import serviceContext from '../services/services-context';


const useServices = () => {

    return useContext(serviceContext);
}

export default useServices;
