import React, { Fragment, useEffect } from 'react'
import LoginPageComponent from '../components/login-page-component/login-page-component'

export default function LoginPage() {

  useEffect(()=>{
    window.location.href = `https://usjnet.sjp.ac.lk/login/user_login_sso`;
  },[])

  return (
   <Fragment>
    <h5>Loading...</h5>
        {/* <LoginPageComponent /> */}
   </Fragment>
  )
}
