import { axiosRefresh } from "../api/axios";
import { useEffect } from "react";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";
import { Navigate, useLocation, useNavigate, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import useLogout from "./useLogout";

const useAxiosRefresh = () => {
    const { auth, setAuth } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const cookies = new Cookies();
    const logout = useLogout();
    const from = location.state?.from?.pathname || "/ ";

    useEffect(() => {
        const requestIntercept = axiosRefresh.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.refreshToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosRefresh.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401) {
                //     const logoutStates = await logout();
                //     if(logoutStates === true){
                //         setAuth({});
                //         cookies.remove('privilages', { path: '/' });
                //         cookies.remove('refreshToken', { path: '/' });
                //         cookies.remove('userState', { path: '/' });
                //     }
                //    window.location.href = '/';
                setAuth({});
                cookies.remove('privilages', { path: '/' });
                cookies.remove('refreshToken', { path: '/' });
                cookies.remove('userState', { path: '/' });
                cookies.remove('loginType', { path: '/' });
                // window.location.href = '/';
                navigate(from, { replace: true });
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosRefresh.interceptors.request.eject(requestIntercept);
        }
    }, [auth])

    return axiosRefresh;
}

export default useAxiosRefresh;