import React, { createContext } from "react";
import { useState } from "react";
import { GetSingleAssignmentDetails } from "./api-services";
import useAuth from "../hooks/useAuth";
import { toast } from "react-toastify";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const serviceContext = createContext();

// previlage Services

const USERPRIVILAGES = {
  'StudentActivity' : 'PRIV_STUDENT_ACTIVITY',
}

// Api Services

const SingleAssignmentsDetails = (assignmentUuid) => {
  GetSingleAssignmentDetails(assignmentUuid);
}

export const ServiceContextProvider = (props) => {

  const { auth } = useAuth();
  const [toastMessage, setToastMessage] = useState();
  const [webInitialData, setWebInitialData] = useState(true);
  const privateAxisos = useAxiosPrivate();
  const [isPageLoad, setIsPageLoad] = useState(false);
  const [iFarameURL, setIFrameURL] = useState("");
 

  const loadInitialData =async () => {  
    alert(""); 
    setWebInitialData(!webInitialData);
}

  const checkAutherization = (allowedRoles) => {
    const hasPrivilege = auth?.privilages?.includes(allowedRoles) || false;
    return hasPrivilege;
  }

  const  padZero = (number) => {
    return number < 10 ? `0${number}` : number;
  }
  
  const dateFromat = (date) => {
    let formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
    return formattedDate;
  }

  const dateFromatStr = (dateStr) => {
    const date = new Date(dateStr);
    let formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
    return formattedDate;
  }

const dateFromatIOS8601Second = (date) => {
  const datePart = date.split('T')[0];
  return datePart;
}

const dateFormatMonthYear = (date) => {
  const parsedDate = new Date(date);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const formattedDate = `${monthNames[parsedDate.getMonth()]}-${parsedDate.getFullYear()}`;
  return formattedDate;
}

const dateFormatDayMonthYear = (date) => {
  const parsedDate = new Date(date);

  const day = parsedDate.getDate();
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const month = monthNames[parsedDate.getMonth()];
  const year = parsedDate.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
}
   
  const dateFromatIOS8601 = (date) => {
    let formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}T${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
    return formattedDate;
  }

  const dateFromatIOS8601Str = (dateStr) => {
    const date = new Date(dateStr);
    let formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}T${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
    return formattedDate;
  }

  const dateTimeFromat = (date) => {
    let formattedDateTime = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
    return formattedDateTime;
  }

  const calAssignmentOverDue = (dueDateStr) => {
    // Assuming the due date is December 22nd, 2023, 14:17
    const dueDate = new Date(dueDateStr);

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = dueDate - currentDate;

    // Calculate days, hours, and minutes
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    var overdue = days + " days, "+ hours + " hours, and "+ minutes + " minutes";
    return overdue;
  }

  const hasDateDefferentwithCurrentDate = (dateStr) => {
    const dueDate = new Date(dateStr);

    // Get the current date and time
    const currentDate = new Date();

    // Compare dates
    const isOverdue = dueDate.getTime() - currentDate.getTime() < 0;
    return isOverdue;
  }

  const capitalizeEachWord = (inputString="") => {
    return inputString
      .toLowerCase()
      .split('/')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('/');
  };

  const dailyDairyStatus = (val) => {
    if(val == 0){
      return "Draft";
    }else if(val == 1){
      return "Submitted";
    }else if(val == 2){
      return "Rejected";
    }else if(val == 3){
      return "Accepted";
    }else{
      return "";
    }
  }

  const assignmentStatus = (val) => {

    if(val == 0){
      return "Saved Assignment";
    }else if(val == 1){
      return "Pending";
    }else if(val == 2){
      return "Accepted Assignment";
    }else if(val == 3){
      return "Rejected Assignment";
    }else if(val == 4){
      return "Submitted Assignment";
    }else if(val == 5){
      return "Completed Assignment";
    }else{
      return "";
    }
  }


  const internshipStatus = (val) => {
    if(val == 0){
      return "submitted";
    }else if(val == 1){
      return "On-Going";
    }else if(val == 2){
      return "Finalized";
    }else{
      return "";
    }
  }

  const showToastSuccessAlert = () => {
    toast.success("", {
      position: toast.POSITION.TOP_RIGHT
    });
  }



  const value = {
    USERPRIVILAGES: USERPRIVILAGES,
    checkAutherization: checkAutherization,
    dateFromat: dateFromat,
    dateTimeFromat: dateTimeFromat,
    dailyDairyStatus: dailyDairyStatus,
    dateFromatIOS8601:dateFromatIOS8601,
    dateFromatIOS8601Second: dateFromatIOS8601Second,
    dateFormatMonthYear: dateFormatMonthYear,
    dateFromatIOS8601Str: dateFromatIOS8601Str,
    dateFromatStr: dateFromatStr,
    dateFormatDayMonthYear: dateFormatDayMonthYear,
    internshipStatus: internshipStatus,
    assignmentStatus: assignmentStatus,
    calAssignmentOverDue: calAssignmentOverDue,
    hasDateDefferentwithCurrentDate: hasDateDefferentwithCurrentDate,
    capitalizeEachWord: capitalizeEachWord,
    showToastSuccessAlert: showToastSuccessAlert,
    webInitialData:webInitialData,
    loadInitialData:loadInitialData,
    setToastMessage, setToastMessage,
    setIsPageLoad:setIsPageLoad,
    isPageLoad:setIsPageLoad,
    iFarameURL:iFarameURL,
    setIFrameURL:setIFrameURL,
  
    

    



    SingleAssignmentsDetails: SingleAssignmentsDetails,
  };
  return (
    <serviceContext.Provider value={[value]}>
      {props.children}
    </serviceContext.Provider>
  );
};

export default serviceContext;
