import React, { Fragment, useState } from 'react'
import { Button} from 'rsuite'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'

export default function UserVerificationForm({handleChange,userDetails,handleOnSubmitUserValidation}) {

    const [validated, setValidated] = useState(false);
    const onSubmitAction = (event) => {
        
        event.preventDefault();
        if (validateForm()) {
            handleOnSubmitUserValidation();
        setValidated(true);
        } else {
        setValidated(false);
        }
    }

 const [errors, setErrors] = useState({
    empNo: '',
    nic: '',
  });


const onValueChange = (event) => {
    const { name, value } = event.target;

    handleChange(name,value);

    // Clear error when user starts typing
    setErrors({
      ...errors,
      [name]: '',
    });
  };   
  
    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!userDetails.empNo) {
        valid = false;
        newErrors.empNo = 'EmpNo/RegNo is required';
        }

        if (!userDetails.nic) {
        valid = false;
        newErrors.nic = 'NIC is required';
        }

        setErrors(newErrors);
        return valid;

    };


  return (
    <Fragment>
        <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}>

                    <Row className='col-sm-9 mt-4'>
                         <Form noValidate validated={validated} onSubmit={onSubmitAction}>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                           
                            <Form.Label column sm={3}>
                            EMP NO / REG No
                            </Form.Label>
                            <Col sm={9}>
                            <Form.Control isInvalid={!!errors.empNo} value={userDetails?.empNo} onChange={onValueChange} name='empNo' type="text" placeholder="EMP NO/ REG No" />
                            <p style={{ color:"red",fontSize:12 }}>{errors.empNo}</p>
                            </Col>
                           
                           
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
                           
                            <Form.Label column sm={3}>
                            NIC No
                            </Form.Label>
                            <Col sm={9}>
                            <Form.Control isInvalid={!!errors.nic} value={userDetails?.nic}  onChange={onValueChange} name='nic' type="text" placeholder="NIC No" />
                            <p style={{ color:"red",fontSize:12 }}>{errors.nic}</p>
                            </Col>

                         
                        </Form.Group>
                        
                       

                        <Form.Group as={Row} className="mb-3">
                            <Col>
                                {/* Empty space to push the button to the end */}
                            </Col>
                            <Col xs="auto">
                                <Button type="submit" style={{ width: '180px', backgroundColor: '#990000', color: 'white' }} >Next</Button>
                            </Col>
                            </Form.Group>
                        </Form>
                    </Row>
                    
                </Row>
    </Fragment>
  )
}
